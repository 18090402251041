.flex {
  display: flex;

  &.wrap {
    flex-wrap: wrap;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-even {
    justify-content: space-evenly;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }

  &.row-reverse {
    flex-direction: row-reverse;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.clickable {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.p-50 {
  padding: 50px;
}

.p-40 {
  padding: 40px;
}

.p-30 {
  padding: 30px;
}

.p-20 {
  padding: 20px;
}

.p-10 {
  padding: 10px;
}

.p-0 {
  padding: 0 !important;
}

.ph-10 {
  padding: 0 10px;
}

.ph-20 {
  padding: 0 20px;
}

.ph-30 {
  padding: 0 20px;
}

.ph-40 {
  padding: 0 40px;
}

.ph-60 {
  padding: 0 60px;
}

.ph-80 {
  padding: 0 80px;
}

.ph-120 {
  padding: 0 120px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pb-20 {
  padding-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-end {
  text-align: end;
}

.capitalize {
  text-transform: capitalize;
}
