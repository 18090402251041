$primary: #ff5b09;
$secondary: #ff864a;
$secondary-2: #ff9e6e;
$light: #f2f6f9;

$active: #00c1a2;
$inactive: #f84960;
$warning: #faad14;
$default: #1890ff;

$neutral: #1e1e1e;

$focused: #ff9e6e;
$disabled: #ff9e6e;

$outline: #ff5b09;
$outline-dark: #1e1e1e;

$box-shadow: 0px 4px 8px 0px rgba(242, 246, 249, 1);
