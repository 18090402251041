/* You can add global styles to this file, and also import other style files */
@import "variables";

//Fonts

@font-face {
  font-family: "Manrope-Regular";
  src: url(assets/fonts/static/Manrope-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope-Bold";
  src: url(assets/fonts/static/Manrope-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: url(assets/fonts/static/Manrope-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope-Light";
  src: url(assets/fonts/static/Manrope-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope-Medium";
  src: url(assets/fonts/static/Manrope-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

// End Fonts

* {
  box-sizing: border-box;
}

body {
  background-color: #f2f6f9;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Manrope-Bold";
}

.display {
  font-family: "Manrope-Bold";
  font-family: 72px;
}

h1 {
  font-size: 30px;
  color: $neutral;
}

h2 {
  font-size: 28px;
  color: $neutral;
}

.ant-drawer-body {
  padding-top: 50px !important;
}

h3 {
  font-size: 24px;
  color: $neutral;
  font-weight: 800;
}

h4 {
  font-size: 20px;
  color: $neutral;
}

h5 {
  font-size: 18px;
  color: $neutral;
}

.neutral {
  color: #4b4b4d !important;
}

p {
  font-family: "Manrope-Regular";
  color: $neutral;
  font-size: 14px;
  margin-bottom: 5px;

  &.ghost {
    opacity: 0.55;
    font-weight: 500;
  }

  &.normal {
    color: #4b4b4d;
    font-weight: 500;
  }

  &.small {
    font-size: 10px;
    font-weight: 600;
  }

  &.semi-bold {
    font-weight: 700;
  }

  &.bold {
    font-weight: 900;
  }

  &.extra-bold {
    font-weight: 1000;
  }

  &.success {
    color: $active;
  }
}

b {
  font-size: inherit;
  font-family: "Manrope-Bold";
}

small {
  font-size: 10px;
  font-family: "Manrope-Regular";
  font-weight: 600;
  line-height: 16px;

  &.tiny {
    font-size: 8px;
  }

  &.medium {
    font-size: 12px;
  }

  &.large {
    font-size: 14px;
  }

  &.thin {
    font-weight: 500;
  }

  &.ghost {
    opacity: 0.55;
  }

  &.bold {
    font-weight: 700;
  }

  &.highlighted {
    color: $primary;
  }
}

.paragraph {
  font-size: 18px;
}

.paragraph-1 {
  font-size: 16px;
}

.paragraph-2 {
  font-size: 14px;
}

.paragraph-3 {
  font-size: 12px;
}

.sub-title {
  font-weight: 700;
  font-family: "Manrope-Bold";
}

.primary {
  color: $primary;
}

.success {
  color: $active;
}

.danger {
  color: $inactive;
}

.warning {
  color: $warning;
}

.color-default {
  color: $default;
}

label {
  font-family: "Manrope-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
}

.bold {
  font-family: "Manrope-Bold";
}

.label {
  font-size: 13px;
  font-weight: 500;
  line-height: 21.86px;
  margin-bottom: 5px;
}

input {
  border-radius: 4px !important;

  &:hover {
    border-color: $focused !important;
  }

  &:focus {
    border-color: $focused !important;
    box-shadow: $box-shadow !important;
  }
}

.ant-menu-item {
  padding: 0 6px !important;
}

.ant-input-disabled {
  color: #646464;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $focused !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border-color: $focused !important;
  box-shadow: $box-shadow !important;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  &.outline {
    color: #a7a7a7;
  }

  background-color: $disabled;
}

nz-pagination {
  padding: 5px !important;
}

.ant-tabs-nav {
  margin: 0 !important;
  padding: 10px 25px;
}

nz-divider {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-top: 2px solid rgb(0, 0, 0, 0.12);
}

.curso {
  cursor: pointer;
}

nz-card {
  border-radius: 8px !important;
}

// Navbar
nz-header {
  li {
    font-family: "Manrope-Regular";
    font-size: 14px;
    color: $neutral;
    font-weight: 700;

    &.highlighted {
      color: $primary;
    }
  }

  &.ant-layout-header {
    color: $neutral;
    background: #fff;
    padding: 0;
  }

  &.ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: $neutral;
    background: #fff;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-vertical .ant-menu-submenu-selected,
  .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: $primary;
  }

  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
    border-bottom: 2px solid $primary;
  }
}

.ant-tabs-tab-btn {
  font-family: "Manrope-Regular";
  font-size: 16px !important;
}

nz-breadcrumb-item {
  font-size: 14px;
  // font-size: 0.8vw;
  font-family: "Roboto-Regular";

  a {
    font-size: 14px;
    // font-size: 0.8vw;
    font-family: "Roboto-Regular";
  }
}

button {
  font-family: "Manrope-Bold";
  font-size: 14px !important;
  border-radius: 4px !important;
  line-height: 24px;

  &.btn {
    font-size: 14px !important;
    font-weight: 400;
  }

  &.danger {
    background-color: $inactive;
  }

  &.small {
    font-size: 12px !important;
  }

  &.large {
    font-size: 16px !important;
  }

  &.btn-extra {
    font-family: "Roboto-Regular";
    line-height: 22px;
    font-size: 14px !important;
    font-weight: 400;
  }

  &.icon-disabled {
    color: #86898b !important;
    background-color: #fff !important;
  }

  &.outline {
    color: $outline;
    border-color: $outline;
    background-color: transparent !important;

    &.danger {
      border-color: $inactive;
      color: $inactive;
    }

    &.disabled {
      color: $secondary-2;
      background-color: transparent !important;
      border-color: $secondary-2;

      &:hover {
        color: $secondary-2;
        border-color: $secondary-2;
        background-color: transparent !important;
      }
    }
  }
}

a {
  font-family: "Manrope-SemiBold";
  font-size: 14px;

  &.default {
    color: #fff;
  }

  &.neutral {
    color: $neutral;
  }

  &.button {
    font-family: "Manrope-Bold";
    font-size: 14px !important;
    border-radius: 4px !important;
    line-height: 24px;
  }

  &.highlighted {
    color: $primary;
  }

  &.medium {
    font-size: 12px;
  }

  &.small {
    font-size: 10px;
  }
}

// Ant Design

.ant-btn-primary {
  color: #fff;
  border-color: $primary;
  background: $primary;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover {
  color: $light;
  border-color: $primary;
  background: $primary;
}

.ant-btn-primary:focus {
  color: $light;
  border-color: $primary;
  background: $primary;
}

nz-divider {
  font-family: "Manrope-Regular";
}

.ant-modal-content {
  border-radius: 8px;
}

table {
  tr {
    td {
      font-size: 14px;
      font-family: "Manrope-Regular";
    }
  }
}

.ant-table-pagination.ant-pagination {
  margin: 20px 0 !important;
}

.hidden {
  visibility: hidden;
}

.no-radius-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.no-radius-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media screen and (max-width: 769px) {
  nz-option-item .ant-select-item-option-content {
    white-space: normal !important;
  }
}

.logo {
  nz-tag {
    display: none;
  }
}
// .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
//   width: 500px !important;
// }

@media screen and (max-width: 769px) {
  .ant-card-body {
    padding: 10px !important;
  }
  .edit-quotation-drawer {
    .transporter-responsive {
      .ant-card-body {
        padding: 12px;

        .transporter-responsive-flex {
          flex-wrap: wrap;
          align-items: flex-start;

          .logo {
            order: 0;
            width: 100%;
            margin-bottom: 10px;
            justify-content: space-between;

            nz-tag {
              display: block;
              margin: 0;
              border-radius: 4px;
            }

            img {
              width: 90px;
              height: auto;
            }
          }

          .price {
            order: 1;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
          }

          .modality {
            order: 2;
          }

          .time {
            order: 3;
          }

          .invoice {
            order: 4;
          }

          .modality,
          .time,
          .invoice {
            width: 33.33%;

            h4 {
              font-weight: 400 !important;
              font-size: 14px;
            }
          }

          .modality,
          .time,
          .price,
          .invoice {
            line-height: 20px;

            p,
            h4 {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
